<template>
  <div class="navbar">
    <router-link to="/home" active-class="active">
      <IconFeed height="30" fill="#ffffff" class="svg-feed default" />
    </router-link>
    <router-link to="/gainers" active-class="active">
      <IconUpArrow fill="#475467" height="25" />
    </router-link>
    <router-link to="/losers" active-class="active">
      <IconUpArrow fill="#475467" height="25" transform="rotate(180)" />
    </router-link>
    <router-link to="/alerts" active-class="active">
      <IconWifi fill="#475467" height="35" />
    </router-link>
    <router-link to="/settings" active-class="active">
      <IconSettings fill="#475467" height="30" />
    </router-link>
  </div>
</template>

<script>
import IconWifi from "@/icons/wifi.svg";
import IconSettings from "@/icons/settings.svg";
import IconUpArrow from "@/icons/up-arrow.svg";
import IconFeed from "@/icons/feed.svg";
export default {
  name: "Navbar",
  components: { IconWifi, IconSettings, IconUpArrow, IconFeed },
};
</script>
<style lang="scss" scoped>
.navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: 0;
  position: fixed;
  background-color: #000;
  left: 0;

  a {
    -webkit-box-flex: 1;
    flex-direction: row;
    height: 44px;
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    &.active {
      svg {
        fill: #ffffff;
        g {
          [fill] {
            fill: #ffffff;
          }
        }
      }
    }
  }
}
.svg-feed.default {
  g {
    [fill] {
      fill: #475467;
    }
  }
}
</style>
