import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/home.vue";
import Login from "../views/home/login.vue";
import Gainers from "../views/gainers.vue";
import Losers from "../views/losers.vue";
import Alerts from "../views/alerts.vue";
// import Settings from "../views/settings.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/gainers",
    name: "Gainers",
    component: Gainers,
    meta: { requiresAuth: true },
  },
  {
    path: "/losers",
    name: "Losers",
    component: Losers,
    meta: { requiresAuth: true },
  },
  {
    path: "/alerts",
    name: "Alerts",
    component: Alerts,
    meta: { requiresAuth: true },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("../views/settings.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/direct",
    name: "Direct",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/direct"),
  },
  {
    path: "/explore",
    name: "Explore",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/explore"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/profile"),
    children: [
      {
        path: "",
        name: "ProfilePost",
        component: () =>
          import(/* webpackChunkName: "profile" */ "../views/profile/post"),
      },
      {
        path: "igtv",
        name: "ProfileIGTV",
        component: () =>
          import(/* webpackChunkName: "profile" */ "../views/profile/igtv"),
      },
      {
        path: "save",
        name: "ProfileSave",
        component: () =>
          import(/* webpackChunkName: "profile" */ "../views/profile/save"),
      },
      {
        path: "tag",
        name: "ProfileTag",
        component: () =>
          import(/* webpackChunkName: "profile" */ "../views/profile/tag"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  var username = Vue.localStorage.get("username");
  console.log(to);
  if (to.name === "Login") {
    console.log("login");

    if (username != null) {
      console.log("username var");
      next({
        name: "Home",
      });
    } else next();
  } else {
    if (to.meta.requiresAuth) {
      console.log("home");
      if (username == null) {
        next({
          name: "Login",
        });
      } else next();
    } else next();
  }
});
export default router;
