<template>
  <div class="header">
    <div class="title">
      <h1>All symbols</h1>
      <button class="btn switchall">
        Switch all <IconWifi fill="#ffffff" height="20" />
      </button>
    </div>

    <div class="content">
      <div class="coinlist">
        <table class="table table-dark" style="">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Price</th>
              <th scope="col">Daily</th>
              <th scope="col">Alerts</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in pairs" :key="index">
              <td class="symbol">
                <span>{{ item.pairSymbol }}</span>
                {{ item.name }}
              </td>
              <td class="price" :class="[item.priceClass]">
                {{ formatPrice(item.price) }}
              </td>
              <!-- <td>{{ item.daily }}%</td> -->
              <td :class="classObject(item.price, item.lastDayClosePrice)">
                {{ DailyPercentShow(item.price, item.lastDayClosePrice) }}%
              </td>
              <td><IconWifi fill="#ffeca7" height="20" /></td>
            </tr>
            <!--<tr id="eth-usd">
                <td>ETHUSD</td>
                <td class="price">54,34535</td>
                <td>0.58</td>
            </tr>-->
          </tbody>
        </table>
      </div>
    </div>
    <Navbar />
  </div>
</template>

<script>
// import Post from "@/components/Post";
// import IconSignal from "@/icons/signal.svg";
import Navbar from "@/components/Navbar";
import IconWifi from "@/icons/wifi.svg";
export default {
  name: "Home",
  components: { IconWifi, Navbar },
  data() {
    return {
      connection: null,
      baseUrl: "https://localhost:44393/api/",
      pairs: [],
    };
  },
  computed: {},
  methods: {
    classObject: function (price, lastDayClosePrice) {
      return {
        "text-success": this.DailyPercentValue(price, lastDayClosePrice) > 0,
        "text-danger": this.DailyPercentValue(price, lastDayClosePrice) <= 0,
      };
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    SendMessage(message) {
      this.connection.send(message);
    },
    DailyPercentValue(price, lastDayPrice) {
      return (((price - lastDayPrice) / lastDayPrice) * 100).toFixed(2);
    },
    DailyPercentShow(price, lastDayPrice) {
      return Math.abs(this.DailyPercentValue(price, lastDayPrice));
    },

    initScoket() {
      var mainThis = this;
      console.log("Starting connection to WebSocket Server");
      var userId = mainThis.$localStorage.get("userID");
      var token = mainThis.$localStorage.get("token");

      // this.connection = new WebSocket(
      //   "ws://192.168.1.34:8081?userId=" + userId + "&token=" + token
      // );
      this.connection = new WebSocket(
        "ws://82.192.82.49:8080?userId=" + userId + "&token=" + token
      );
      this.connection.onopen = function () {
        console.log("Successfully connected to the echo websocket server...");
        var message = { eventName: "GetSymbolsWithPrice", eventData: "" };
        //console.log(this)
        mainThis.SendMessage(JSON.stringify(message));
      };
      this.connection.onerror = function (event) {
        console.log(event);
      };
      this.connection.onclose = function (event) {
        console.log(event);
      };
      this.connection.onmessage = function (event) {
        var obj = JSON.parse(event.data);
        if (obj.messageType == "AllSymbolsWithPrice") {
          console.log(obj);
          mainThis.pairs = obj.data;
        } else if (obj.messageType == "SymbolPrice") {
          var pair = mainThis.pairs.find(
            (x) => x.tradingPairID == obj.data.tradingPairID
          );
          var pairIndex = mainThis.pairs.findIndex(
            (x) => x.tradingPairID == obj.data.tradingPairID
          );

          if (pairIndex != -1) {
            var priceClass =
              obj.data.price > pair.price
                ? "up-color-animation"
                : "down-color-animation";
            obj.data.priceClass = priceClass;
            //obj.data.useEffect = obj.data.price > pairIndex.price;
            //if (obj.data.useEffect) {
            //    console.log(obj.data.useEffect)
            //}
            //console.log(mainThis.$refs[obj.data.pairSymbol]);
            //if (mainThis.$refs[obj.data.pairSymbol]) {
            //    //console.log(mainThis.$refs[obj.data.pairSymbol][0].classList);

            //    setTimeout(() =>

            //        mainThis.$refs[obj.data.pairSymbol][0].classList.add(priceClass)
            //        , 1000)
            //    //console.log($(mainThis.$refs[obj.data.pairSymbol]).classList);
            //    /*mainThis.$refs[obj.data.pairSymbol].addclass(priceClass)*/
            //}
            mainThis.pairs.splice(pairIndex, 1, obj.data);
          } else {
            mainThis.pairs.push(obj.data);
          }
        } else if (obj.messageType == "Connected") {
          console.log("received connected info from Socket ");
        } else {
          console.log("message");
        }
      };
    },
  },
  created() {
    this.initScoket();
  },
  mounted() {
    console.log("home component mounted");
  },
};
</script>
<style lang="scss" scoped>
button.switchall {
  svg {
    margin-left: 5px;
  }
}

.table-dark {
  --bs-table-bg: #000;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: rgb(45, 51, 74);

  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: rgb(45, 51, 74) !important;
  }
  th {
    font-size: 12px;
    letter-spacing: 0.5px;
  }
  th:not(:first-child),
  td:not(:first-child) {
    text-align: right;
    padding-right: 20px;
  }
}

div.title {
  position: sticky;
  height: auto;
  background-color: #000;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  padding: 24px 10px 10px 10px;
  h1 {
    color: rgb(255, 236, 167);
    font-size: 20px;
    font-weight: 600;
  }
  .switchall {
    color: #fff;
    border-radius: 100px;
    background-color: rgb(14, 22, 28);
    font-size: 12px;
    padding: 8px 15px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
}

.header {
  max-width: 605px;
  margin-left: auto;
  margin-right: auto;
  @media (--t) {
    max-width: none;
    display: grid;
    grid-template-columns: 1fr 295px;
    grid-gap: 30px;
  }
}
.content {
  display: block;
  table {
    tbody tr {
      vertical-align: middle;
    }
  }
  .coinlist {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #000;
  }
  .symbol {
    color: #fff;
    font-size: 13px;
    letter-spacing: 0.5px;
    span {
      color: rgb(135, 153, 165);
      display: block;
      font-size: 12px;
    }
  }
  .price {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.6px;
  }
}
@keyframes down-color-key-frame {
  from {
    color: white;
  }

  to {
    color: red;
  }
}
@keyframes up-color-key-frame {
  from {
    color: white;
  }

  to {
    color: green;
  }
}

.up-color-animation {
  animation-name: up-color-key-frame;
  animation-duration: 0.5s;
}
.down-color-animation {
  animation-name: down-color-key-frame;
  animation-duration: 0.5s;
}
</style>