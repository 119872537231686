<template>
  <div>
    <h1>Losers</h1>
    <Navbar />
  </div>
</template>




<script>
import Navbar from "@/components/Navbar";
export default {
  name: "Losers",
  components: { Navbar },
  created() {
    console.log("Losers created");
  },
};
</script>

<style>
</style>