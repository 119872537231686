<template>
  <div class="login">
    <div class="container login-form dark">
      <div class="row">
        <div class="col-10 mx-auto">
          <h1 class="login-title">Log in</h1>
          <div class="form-group">
            <label for="email" class="text-center">User name</label>
            <input
              type="text"
              id="email"
              v-model="username"
              class="form-control"
              placeholder="enter your user name"
            />
          </div>
          <div class="form-group mt-4">
            <button class="btn btn-block btn-danger" @click="login">
              Login
              <i
                v-if="loading"
                class="fa fa-spinner fa-spin"
                aria-hidden="true"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const api = process.env.VUE_APP_API_URL;

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      loading: false,
    };
  },
  methods: {
    login() {
      if (this.$localStorage.get("userID") == null) {
        console.log("userID is null");
        this.CheckUsername();
        console.log("sdfsdf");
      } else {
        if (this.$localStorage.get("token") != null) {
          console.log("token is not null");
          this.$router.push("/home");
        }
      }
    },
    CheckUsername() {
      this.loading = true;
      this.$http
        .get(`${api}users/v1/username/${this.username}`)
        .then((res) => {
          console.log(res);
          this.loading = false;
          this.$localStorage.set("userID", res.body.model.userID);
          this.$localStorage.set("username", res.body.model.username);

          this.token();
          // this.$router.push("/home");
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$notify({
            group: "foo",
            text: "Wrong username.",
            type: "warn",
          });
        });

      // this.$router.push('/home');
    },
    token() {
      var username = this.$localStorage.get("username");
      var userID = this.$localStorage.get("userID");

      this.loading = true;
      this.$http
        .post(`${api}auth/v1/token/`, {
          userID: userID,
          username: username,
        })
        .then((res) => {
          console.log(res);
          this.$localStorage.set("token", res.body.model.token);
          this.$localStorage.set("tokenexpiresIn", res.body.model.expiresIn);
          this.$router.push("/home");
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });

      // this.$router.push('/home');
    },
  },
  mounted() {
    console.log("mounted");

    // this.$notify({
    //   group: "foo",
    //   title: "Important message",
    //   text: "Hello user! This is a notification!",
    // });
    // this.$localStorage.set("username", this.username);
    // if (this.$localStorage.get("userID") == null) {
    //   console.log("userID is null");
    //   this.login();
    //   console.log("sdfsdf");
    // } else {
    //   if (this.$localStorage.get("token") != null) {
    //     console.log("token is not null");
    //     this.$router.push("/home");
    //   }
    // }
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  height: 100vh;
  padding-top: 200px;
  label {
    font-size: 14px;
    font-weight: bold;
    color: #b0adad;
    display: inline-block;
    margin-bottom: 0.5rem;
  }
  .form-control {
    border: none;
    border-bottom: 1px solid #e7e7e7;
    border-radius: 0;
    padding: 9px 5px;
    min-height: 40px;
    font-size: 18px;
    font-weight: normal;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}
.login-title {
  font-size: 30px;
  color: #000;
  font-weight: bold;
  margin-bottom: 25px;
}
</style>