<template>
  <div class="app">
    <!-- <Login></Login> -->
    <notifications group="foo" />

    <router-view />

    <!-- <MasterHeader />

    <main class="main">
      <Container>
        <router-view />
      </Container>
    </main> -->
    <!-- <router-view /> -->
  </div>
</template>

<script>
// import MasterHeader from "@/components/Header";
// import Container from "@/components/Container";
// import Login from "./views/home/login.vue";

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;
const API = process.env.VUE_APP_API_URL;

export default {
  name: "App",
  components: {
    // Login,
    // Container,
    // MasterHeader,
  },

  metaInfo() {
    return {
      title: "Coin Push",
      meta: [
        {
          name: "viewport",
          content:
            "width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1, user-scalable=no",
        },
        {
          name: "theme-color",
          content: "#4DBA87",
        },
      ],
    };
  },
  mounted() {
    console.log(VUE_APP_BASE_URL);
    console.log(API);
  },
};
</script>
<style lang="sass" scoped>
$light: #dddddd
$dark: #011100
.main
  padding-top: 40px
</style>
